












































import {
  defineComponent,
  useFetch,
  ref
} from '@nuxtjs/composition-api';
import { useBlog } from '@wemade-vsf/blog';
import { useImage } from '@wemade-vsf/composables';
import type { BlogPost as BlogPostInterface } from '@wemade-vsf/magento-api';
import CtaHeading from 'components/theme/Content/CtaHeading.vue';
import BlogPost from 'components/blog/BlogPost.vue';

export default defineComponent({
  name: 'BlogPostList',
  props: {
    title: {
      type: String,
      default: ''
    },
    showLink: {
      type: Boolean,
      default: false
    },
    linkText: {
      type: String,
      default: ''
    },
    linkUrl: {
      type: String,
      default: ''
    },
    numPosts: {
      type: Number,
      default: 3
    },
    type: {
      type: String,
      default: 'new'
    },
    topicId: {
      type: Number,
      default: 0
    },
    sku: {
      type: String,
      default: ''
    },
    linkDisplay: {
      type: String,
      default: 'link'
    },
    postLinkDisplay: {
      type: String,
      default: 'link'
    }
  },
  components: {
    CtaHeading,
    BlogPost
  },
  setup (props) {
    const { loading, getPostList } = useBlog();
    const posts = ref<BlogPostInterface[]>([])

    useFetch(async () => {
      let query = {
        pageSize: props.numPosts,
        currentPage: 1
      }
      if (props.type === 'topic' && props.topicId) {
        query['action'] = 'get_post_by_topic'
        query['topicId'] = props.topicId
      }

      const result = await getPostList(query)
      posts.value = result?.items || []
    })

    const { getMagentoImage } = useImage();

    return {
      loading,
      getMagentoImage,
      posts
    }
  }
})
